import Container from "@material-ui/core/Container"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { borderRadius } from "../../theme/globalStyles"
import {
  PageBuilderActiviteitImageFragment,
  PageBuilderErvaringImageFragment,
  PageBuilderImageFragment,
  PageBuilderNieuwsImageFragment,
  PageBuilderVacatureImageFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  block:
    | PageBuilderImageFragment
    | PageBuilderErvaringImageFragment
    | PageBuilderActiviteitImageFragment
    | PageBuilderNieuwsImageFragment
    | PageBuilderVacatureImageFragment
}

const StyledImg = styled(GatsbyImage)<GatsbyImageProps>`
  ${borderRadius};
  box-shadow: ${({ theme }) => theme.shadows[5]};
`

const ImgWrapper = styled.div`
  left: 50%;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6.5)}px;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
`

const Img: React.FC<IProps> = ({ block }) => (
  <ImgWrapper>
    <Container maxWidth={block.maxWidth as MaxWidthMuiKeys}>
      <StyledImg
        image={block.afbeelding?.localFile?.childImageSharp?.gatsbyImageData}
        alt={String(block.afbeelding?.altText)}
      />
    </Container>
  </ImgWrapper>
)

export default Img
